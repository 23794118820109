var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "500" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "#00aff0" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.attr.name))]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            {
              staticClass: "pt-3",
              attrs: { flat: "", color: "grey lighten-3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "background-color": "#fff",
                  label: "Add " + _vm.attr.name,
                  autocomplete: "off"
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save($event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "append-outer",
                    fn: function() {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { small: "", icon: "" },
                                          on: { click: _vm.save }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "blue" } },
                                          [_vm._v("mdi-plus")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v("Add")])]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.newval,
                  callback: function($$v) {
                    _vm.newval = $$v
                  },
                  expression: "newval"
                }
              })
            ],
            1
          ),
          _c("v-divider"),
          _vm.attrvalues.length >= 1
            ? _c(
                "section",
                {
                  staticClass: "pa-4",
                  staticStyle: { "max-height": "340px", overflow: "auto" }
                },
                [
                  _vm._l(_vm.attrvalues, function(attrVal, idx) {
                    return _c("v-text-field", {
                      key: idx,
                      attrs: {
                        readonly: _vm.activeIdx !== idx,
                        outlined: "",
                        dense: "",
                        color: _vm.activeIdx !== idx ? "grey" : "primary"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "append-outer",
                            fn: function() {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      icon: "",
                                                      color:
                                                        _vm.activeIdx !== idx
                                                          ? ""
                                                          : "green"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.update(
                                                          idx,
                                                          attrVal
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.activeIdx !== idx
                                                            ? "mdi-pencil-outline"
                                                            : "mdi-check"
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.activeIdx !== idx
                                            ? "Modify"
                                            : "Save"
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      icon: "",
                                                      color: "red"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.remove(
                                                          attrVal
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-trash-can-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Remove")])]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: attrVal.value,
                        callback: function($$v) {
                          _vm.$set(attrVal, "value", $$v)
                        },
                        expression: "attrVal.value"
                      }
                    })
                  }),
                  _vm.showNotification
                    ? _c(
                        "v-alert",
                        {
                          attrs: { text: "", dense: "", type: _vm.notification }
                        },
                        [_vm._v(" { {text }} ")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                "section",
                { staticClass: "pa-6" },
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("h3", [_vm._v("No Values to Display")])
                  ])
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }