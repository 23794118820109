<template>
  <v-dialog :value="value" persistent width="500">
    <v-card>
      <v-toolbar flat dark color="#00aff0">
        <v-toolbar-title>{{ attr.name }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-toolbar flat class="pt-3" color="grey lighten-3">
        <v-text-field
          outlined
          dense
          background-color="#fff"
          :label="`Add ${attr.name}`"
          v-model="newval"
          autocomplete="off"
          @keydown.enter="save">
          <template v-slot:append-outer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small icon @click="save">
                  <v-icon color="blue">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-toolbar>
      <v-divider />
      <section v-if="attrvalues.length >= 1" class="pa-4" style="max-height:340px; overflow:auto;">
        <v-text-field
          :readonly="activeIdx !== idx"
          outlined
          dense
          :color="activeIdx !== idx ? 'grey' : 'primary'"
          v-for="(attrVal, idx) in attrvalues"
          :key="idx"
          v-model="attrVal.value">
          <template v-slot:append-outer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn small icon
                  v-on="on"
                  :color="activeIdx !== idx ? '' : 'green'"
                  @click="update(idx, attrVal)">
                  <v-icon>
                    {{ (activeIdx !== idx) ? 'mdi-pencil-outline' : 'mdi-check' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ (activeIdx !== idx) ? 'Modify' : 'Save' }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn small icon
                  v-on="on"
                  color="red"
                  @click="remove(attrVal)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Remove</span>
            </v-tooltip>
          </template>
        </v-text-field>
        <v-alert v-if="showNotification" text dense :type="notification">
          { {text }}
        </v-alert>
      </section>
      <section class="pa-6" v-else>
        <v-row justify="center">
          <h3>No Values to Display</h3>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>
<script>
// API
import attrApi from '@/axios/party-attribute-endpoint'

// Mixins
import { displayAlert } from '@/mixins/alert'

export default {
  name: 'MultiValue',
  data () {
    return {
      activeIdx: '',
      newval: '',
      notification: '',
      originalVal: null,
      showNotification: false,
      text: ''
    }
  },
  props: [
    'value',
    'attr',
    'attrvalue',
    'attrvalues'
  ],
  mixins: [displayAlert],
  methods: {
    async save () {
      if (!this.newval) return
      const attrVal = {
        attr_id: this.attr.id,
        created_by: this.$store.getters.email,
        party_id: this.$route.query.id,
        value: this.newval
      }
      await attrApi.attributeValue.post(attrVal)
        .then(() => {
          this.emitAlert(true, 'success', `${attrVal.value} has been successfully saved.`)
          this.newval = ''
        }).catch(err => {
          this.handleError(err)
        }).finally(() => {
          this.$emit('refresh-values')
        })
    },
    async update (idx, attrVal) {
      this.activeIdx = idx
      if (this.originalVal === null) {
        this.originalVal = attrVal.value
      } else if (this.originalVal !== attrVal.value) {
        const attrValObj = {
          attr_id: this.attr.id,
          updated_by: this.$store.getters.email,
          party_id: this.$route.query.id,
          value: attrVal.value
        }
        await attrApi.attributeValue.put(attrVal.id, attrValObj)
          .then(() => {
            this.emitAlert(true, 'success', `${attrVal.value} has been successfully updated.`)
            this.originalVal = null
            this.activeIdx = ''
          }).catch(err => {
            this.handleError(err)
          }).finally(() => {
            this.$emit('refresh-values')
          })
      } else {
        this.originalVal = null
        this.activeIdx = ''
      }
    },
    async remove (attrVal) {
      const attrValObj = {
        attr_id: this.attr.id,
        updated_by: this.$store.getters.email,
        party_id: this.$route.query.id,
        value: attrVal.value
      }
      await attrApi.attributeValue.delete(attrVal.id, attrValObj)
        .then(() => {
          this.emitAlert(true, 'success', `${attrVal.value} has been successfully removed.`)
        }).catch(err => {
          this.handleError(err)
        }).finally(() => {
          this.$emit('refresh-values')
        })
    }
  }
}
</script>
